import React from 'react';
import './App.css';

import grazette from "./grazette.png"
import loreal from "./loreal.png"
import oway from "./oway.png"
import hairtalk from "./hairtalk.png"

const App = () => {
  return (
    <div className="App">
      <header className="AppHeader">
      
        <div className="AppHeader__content">
          <h1>Skalbolaget</h1>
          <p>Götgatan 101 | <a href="tel:086422150">08-64 22 150</a></p>
          <p>Carina | Åsa | Cathrin</p>
        </div>
        <div className="AppHeader__shadow" />  
      </header>
      <div className="container">
        <div className="Content">
          <aside className="SecondaryContent">

            <section className="SecondaryContent__section">
              <h2>Öppettider</h2>
              <p><span className="bold">Mån - Fre:</span> 09.30 - 18.00 (eller enligt överrenskommelse)</p>
            </section>

            <section className="SecondaryContent__section">
              <h2>Kontakt</h2>
              <ul className="TextList">
                <li className="TextList__item"><span className="bold">Telefon:</span> <a href="tel:086422150">08-64 22 150</a></li>
                <li className="TextList__item"><span className="bold">Adress:</span> Götgatan 101, 116 62 Stockholm</li>
                <li className="TextList__item"><a target="_blank" rel="noopener noreferrer" href="https://www.google.se/maps/place/Skalbolaget/@59.3072543,18.0749155,17z/data=!3m1!4b1!4m5!3m4!1s0x465f77f428732907:0xe873e39ec6591baf!8m2!3d59.3072516!4d18.0771042">Hitta hit</a></li>
              </ul>
            </section>

          </aside>
        </div>

        <section>
          <h2>Samarbetspartners</h2>
          <div className="PartnersList">
            <div>
              <img src={loreal} alt="Loreal" />
            </div>
            <div>
              <img className="PartnersList__oway" src={oway} alt="Oway" />
            </div>
            <div>
            < img src={grazette} alt="Grazette" />
            </div>
            <div>
            <img src={hairtalk} alt="Hairtalk" />
            </div>
          </div>
        </section>

        <footer className="Footer">
          <p>Skalbolaget</p>
          <p>Götgatan 101, 116 62 Stockholm</p>
        </footer>
      </div>
    </div>
  )
}
export default App;
